import React from 'react'
import UserContext from "../userContext";
import { useHistory } from "react-router-dom";
import SubmitButton from "../Layout/Components/Sub-Components/Submit-button";
import '../Styles/login.scss'
import Image from 'react-image-webp';
export default function Login() {
    const [email, setEmail] = React.useState('')
        , [password, setPassword] = React.useState('')
        , [remember, setRemember] = React.useState(false)
        , [loading, setLoading] = React.useState(false)

    const userContext = React.useContext(UserContext)
        , history = useHistory();


    return (
        <div className="parent">
            <div className={'background'} />
            <div className="container full-height align-items-center">
                <div className={'row full-height align-items-center justify-content-md-center'}>
                    <div className={'col col-lg-5 col-sm-12 align-items-center align-content-lg-center loginBox'}>
                        <div className={'content'}>
                            <Image webp={"https://i.ibb.co/3CgD7G4/new-Colors-Logo-2.webp"} src={"https://i.ibb.co/3CgD7G4/new-Colors-Logo-2.webp"} alt={"logo"} className={"col-6"} />
                            <div className={"col-12"}>
                                <h3>MYPacôme</h3>
                                <h4>Concierge Manager</h4>
                            </div>

                            <form className="user" role-supports-aria-props={"wrong"} onSubmit={(e) => {
                                e.preventDefault()
                                setLoading(true)
                                userContext.apiReducer('login', {
                                    email: email,
                                    password: password
                                }, { remember: remember })
                                    .then(() => {
                                        setLoading(false)
                                        history.push('/')
                                    })
                                    .catch((err) => {
                                        setLoading(false)
                                        if (err.includes("401") || err.includes("500")) alert('Erreur, compte non reconnu ou identifiants érronés')

                                    })
                            }}>
                                <div className="form-group">
                                    <input type="email" className="form-control form-control-user"
                                        id="exampleInputEmail" aria-describedby="emailHelp"
                                        placeholder="Adresse email" value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value)
                                        }} required />
                                </div>
                                <div className="form-group">
                                    <input type="password"
                                        className="form-control form-control-user"
                                        id="exampleInputPassword" placeholder="Mot de passe"
                                        value={password} onChange={(e) => {
                                            setPassword(e.target.value)
                                        }}
                                        required />
                                </div>
                                <div className="form-group">
                                    <div className="custom-control custom-checkbox small">
                                        <input type="checkbox" className="custom-control-input"
                                            id="customCheck"
                                            onChange={e => setRemember(e.target.checked)}
                                            checked={remember} />
                                        <label className="custom-control-label"
                                            htmlFor="customCheck">
                                            Se souvenir de moi
                                        </label>
                                    </div>
                                </div>
                                <SubmitButton additionnalClass="fancy-button" loading={loading.toString()}>Connexion</SubmitButton>
                            </form>
                            <hr />
                            <div className="text-center">
                                <button className="btn btn-user btn-block fancy-button" onClick={() => {
                                    history.push('/reset')
                                }}>
                                    Mot de passe oublié ?
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}