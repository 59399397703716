import React from "react";
import HomeViewCard from "./HomeViewCard";

// context

import userContext from '../../../userContext'

export default function HomeDetails(props) {
    const context = React.useContext(userContext)
    const burl = context.getBurl()

let actualHome= props.sel.property
let homeData = props.data

if(homeData && homeData.properties.map((property) => property.name).includes(actualHome)) {
    const home = homeData.properties.find((property) => property.name === actualHome);
    return (
        <>
            {
                home.homeView?.map(data => {
                    const title=data.title
                    const text=data.text
                    const images = data.images.map((image) => {
                        return burl + "/uploads/" + image
                    });
                    const formattedText = text.split('\n').map((line, index) => <p key={index}>{line}</p>);
                    return (
                        <HomeViewCard title={title} images={images} text={formattedText}/>
                    )
                })
            }
        </>
    ) 
}
    return(
        <div>erreur</div>
    )
}