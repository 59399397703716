import InventoryFormRow from "./ValidateInventoryForm/InventoryFormRow";
import InventoryFormInput from "./ValidateInventoryForm/InventoryFormInput";
import InventoryFormSection from "./ValidateInventoryForm/InventoryFormSection";
import AccordionBody from "../Accordion/AccordionBody";
import AccordionCard from "../Accordion/AccordionCard";
import InventorySignature from "./ValidateInventoryForm/InventorySignature";

export default function ValidateInventoryCheckIn({ sel, imageChange, setImagesURLs, imagesURLs, loading, userContext }) {
    const required = (sel.valid && sel.valide)
    const property = userContext.data?.properties.find(el => el.name === sel.property)
    const user = userContext.data.user
    let inventory = sel.booking?.inventory || {}
    let inv
    try {
        inv = JSON.parse(inventory).entree
    } catch {
        inv = {}
    }



    const slugify = (text) => {
        return text
            .toString()                   // Cast to string (optional)
            .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
            .toLowerCase()                // Convert the string to lowercase letters
            .trim()                       // Remove whitespace from both sides of a string (optional)
            .replace(/\s+/g, '-')         // Replace spaces with -
            .replace(/[^\w-]+/g, '')     // Remove all non-word chars
            .replace(/_/g, '-')           // Replace _ with -
            .replace(/--+/g, '-')       // Replace multiple - with single -
            .replace(/-$/g, '');         // Remove trailing -
    }

    const agency = "MYPacôme Conciergerie"
    const agencyAddress = "3 Impasse de Grieu 14130 Pont-L'Évêque"

    // Make the list of rooms to be able to easily add / remove them, with the other ones having custom text
    const normalRooms = ["Entrée", "Salon", "Salle à manger", "Cuisine / Cellier", "Chambre(s)", "Salle(s) de bain", "WC"]
    try {
        inventory = JSON.parse(inventory)
    } catch {
        inventory = {}
    }
    try {


        return (
            <div className="validate-inventory">
                <p className="inventory-notice">
                    <span style={{ fontWeight: '900' }}>Attention,</span> les rapports de Check-in / Check-out ont changés : <br />
                    Vous devez maintenant remplir ce document et non le document papier. <br />
                    Tous les champs doivent être remplis sous peine d'impossibilité de soumettre ce document. <br />
                    <br />
                    Certains champs sont pré-remplis mais peuvent être modifiés en cas d'erreur / changement</p>
                <br />
                <br />

                <h2>Check-in</h2>
                <hr />
                <br />

                <InventoryFormSection title={"Mandataire"}>
                    <InventoryFormRow>
                        <InventoryFormInput readOnly={required} name={"in-agency"} label={"Agence"} defaultValue={agency} />
                        <InventoryFormInput readOnly={required} name={"in-agencyAdress"} label={"Adresse de l'agence"} defaultValue={agencyAddress} />
                    </InventoryFormRow>
                    <InventoryFormRow>
                        <InventoryFormInput readOnly={required} name={"in-represented"} label={"Représenté par"} defaultValue={user.name + " " + user.lastName} />
                    </InventoryFormRow>
                </InventoryFormSection>

                <InventoryFormSection title={"Dates clés"}>
                    <InventoryFormRow>
                        <InventoryFormInput readOnly={required} name={"in-start"} label={"Date d'entrée"} type={'date'} date={sel.booking.dates.start} />
                        <InventoryFormInput readOnly={required} name={"in-end"} label={"Date de sortie"} type={'date'} date={sel.booking.dates.end} />
                    </InventoryFormRow>
                </InventoryFormSection>

                <InventoryFormSection title={"Locataire"}>
                    <InventoryFormRow>
                        <InventoryFormInput readOnly={required} name={"in-locataire"} label={"Nom du locataire"} defaultValue={sel.booking.people.name} />
                    </InventoryFormRow>
                </InventoryFormSection>

                <InventoryFormSection title={"Lieu loué"}>
                    <InventoryFormRow>
                        <InventoryFormInput readOnly={required} name={"in-property-address"} label={"Adresse du bien"} defaultValue={`${property.address.number} ${property.address.road} ${property.address.zip} ${property.address.city}`} />
                    </InventoryFormRow>
                </InventoryFormSection>

                <InventoryFormSection title={"Clé / Bips"}>
                    <p>Veuillez renseigner pour chaque champ le nombre correspondant.</p>
                    <InventoryFormRow>
                        <InventoryFormInput readOnly={required} defaultValue={inv['in-key-entree']} name={"in-key-entree"} size={2} type={"dropdownNumber"} label={"Porte d'entrée"} />
                        <InventoryFormInput readOnly={required} defaultValue={inv['in-key-portail']} name={"in-key-portail"} size={2} type={"dropdownNumber"} label={"Portail"} />
                        <InventoryFormInput readOnly={required} defaultValue={inv['in-key-bip']} name={"in-key-bip"} size={2} type={"dropdownNumber"} label={"Bip"} />
                        <InventoryFormInput readOnly={required} defaultValue={inv['in-key-garage']} name={"in-key-garage"} size={2} type={"dropdownNumber"} label={"Garage"} />
                        <InventoryFormInput readOnly={required} defaultValue={inv['in-key-autres']} name={"in-key-autres"} size={2} type={"dropdownNumber"} label={"Autre(s)"} />
                    </InventoryFormRow>
                </InventoryFormSection>

                <InventoryFormSection title={"Pièces de la maison"}>
                    <p>Pour chaque pièce, veuillez noter son état sur 5 et commenter pour toutes précisions.<br /> Les commentaires ne sont pas obligatoires mais sont attendus en cas de note faible <br />(5 : Tout va bien, 1 : Rien ne va)</p>
                    {
                        // These rooms are all normal so just loop on them for an easier time
                        normalRooms.map((roomName, index) => {
                            return (
                                <InventoryFormRow key={index}>
                                    <InventoryFormInput readOnly={required} defaultValue={inv["in-grade-" + slugify(roomName)]} name={"in-grade-" + slugify(roomName)} title={roomName} label={"État général"} type={"dropdownColour"} />
                                    <InventoryFormInput readOnly={required} defaultValue={inv["in-comments-" + slugify(roomName)]} name={"in-comments-" + slugify(roomName)} size={10} label={"Commentaires"} type={"textarea"} />
                                </InventoryFormRow>
                            )
                        })
                    }
                    <InventoryFormRow>
                        <InventoryFormInput readOnly={required} defaultValue={inv["in-grade-privatised"]} name={"in-grade-privatised"} note={"(Balcon, terrasse, parking, garage, jardin, piscine)"} title={"Parties privatisées"} label={"Commentaires"} type={"textarea"} size={10} />
                    </InventoryFormRow>

                    <InventoryFormRow>
                        <InventoryFormInput readOnly={required} defaultValue={inv["in-grade-equipments"]} name={"in-grade-equipments"} note={"(Sonnette, interphone, boîte aux lettres, portail, cheminée, poêle….)"} title={"Autres équipements"} label={"Commentaires"} type={"textarea"} size={10} />
                    </InventoryFormRow>

                    <InventoryFormRow>
                        <InventoryFormInput readOnly={required} defaultValue={inv["in-grade-general"]} name={"in-grade-general"} note={"Avis général sur la propriété et le check-in."} title={"Observations générales et suggestions"} label={"Commentaires"} type={"textarea"} size={10} />
                    </InventoryFormRow>

                </InventoryFormSection>

                <InventoryFormSection title={"Compteurs"}>
                    <InventoryFormRow>
                        <InventoryFormInput readOnly={required} defaultValue={inv["in-count-water"]} name={"in-count-water"} label={"Eau"} />
                        <InventoryFormInput readOnly={required} defaultValue={inv["in-counter-gas"]} name={"in-counter-gas"} label={"Gaz"} />
                    </InventoryFormRow>
                </InventoryFormSection>

                <InventoryFormSection title={"Règles"}>
                    <p>Passez en revue les règles avec le locataire afin que tout soit clair.<br />
                        Les règles seront également disponibles en Anglais et en Allemand par mail.</p>
                </InventoryFormSection>

                <div className="mx-0">

                    <h5>A l'arrivée dans le logement</h5>
                    <AccordionBody>
                        <AccordionCard title={"Dispositif et électroménager"} content={"Si une machine ou un dispositif ne fonctionne pas ou que vous n’êtes pas sûr de savoir l’utiliser, contactez votre concierge. En cas de panne, merci de nous contacter."} />
                        <AccordionCard title={"Nettoyage du logement"} content={"Toute réclamation doit être effectuée le jour de votre arrivée pour être recevable. Nous mettons à votre disposition en option serviettes de bain, draps."} />
                    </AccordionBody>

                    <br />
                    <h5>Règles de base</h5>

                    <AccordionBody>
                        <AccordionCard title={"Non-fumeur"} content={"Le logement est entièrement non-fumeur."} />
                        <AccordionCard title={"Animaux"} content={"Autant que nous aimons les chiens et les chats ou tout autre animal, ils ne sont autorisés dans le logement que sous permission directe du propriétaire."} />
                        <AccordionCard title={"Amis et famille"} content={"Nous vous demandons de respecter le nombre de personnes maximum autorisé dans le logement ainsi que le nombre de personnes confirmés au moment de la réservation."} />
                        <AccordionCard title={"Meubles"} content={"Nous vous demandons de ne pas déplacer le mobilier à l’intérieur de l’appartement."} />
                        <AccordionCard title={"Toilettes / WC"} content={"Ne rien jeter dans les toilettes autres que du papier toilette, des poubelles sont à votre disposition."} />
                        <AccordionCard title={"Objets et décorations"} content={"Tous les objets que vous trouvez dans l’appartement nous appartiennent et ne doivent être ni touchés, ni retirés."} />
                        <AccordionCard title={"Horaires calmes"} content={"Tout le monde aime bavarder et faire la fête, mais nos voisins sont beaucoup moins d’accord ! Entre 22h00 et 09h00, nous vous demandons de réduire le bruit au maximum."} />
                        <AccordionCard title={"Clés"} content={"Au moment de votre arrivée, nous vous confions un jeu de clés. Attention à ne pas le perdre. Le cas échéant nous prévoyons des coûts additionnels."} />
                        <AccordionCard title={"Départ"} content={"Horaire limite pour votre départ à 10h00 le matin, si vous souhaitez prolonger votre séjour merci de nous contacter."} />
                        <AccordionCard title={"Avant de quitter le logement"} content={"Faire votre vaisselle / vider le lave-vaisselle. Merci de sortir les poubelles à l’endroit indiqué par le concierge, le service de ramassage passe tous les matins. Vous vous assurer d’avoir fermé toutes les fenêtres. Assurez-vous de n’avoir rien oublié, y compris chargeurs, téléphones, tablettes etc.... Fermer la porte du logement et donner la clef à votre concierge."} />
                        <AccordionCard title={"Dommage des lieux"} content={"Nous savons que les accidents sont des choses qui arrivent. Après votre départ, nous vérifierons le logement pour s’assurer qu’il nous est rendu dans son état initial. En cas de dommage des lieux, les coûts de réparation ou de remise en bon état seront réglés via la carte de crédit déposée en garantie lors de la réservation."} />
                        <AccordionCard title={"Nettoyage"} content={"Il n’est pas nécessaire de nettoyer le logement avant votre départ. Nous vous demandons simplement de le laisser propre."} />
                        <AccordionCard title={"Caution"} content={"Remise sous 14 jours sous réserve d'après ménage de sortie."} />
                    </AccordionBody>

                    {property.pool ? (
                        <>
                            <h5>Piscine</h5>
                            <input type="hidden" name="in-pool" value="true" />
                            <AccordionBody>
                                <AccordionCard title={"Décharge piscine"} content={`Il appartient  à nos locataires de prendre toutes les précautions nécessaires pour l'usage de ces installations, en particulier s'ils séjournent avec de jeunes enfants dont ils doivent assurer la surveillance.
                                    Les locataires reconnaissent dégager entièrement la responsabilité du propriétaire en cas d'accident survenant à eux-mêmes, leur famille ou leurs invités. (Afin d'éviter tous risques d'accidents, nous vous demandons de surveiller particulièrement les jeunes enfants).
                                    Les locataires reconnaissent que la sécurité concernant l'accès à la piscine respecte les normes en vigueur, et ce grâce à la présence d'un rideau dont le fonctionnement est expliqué à l’arrivée. 
                                    La piscine étant très peu profonde les plongeons et sauts sont dangereux et interdits. 
                                    L’utilisation des bouées et autres matériels est sous la responsabilité des locataires.
                                    Aucune indemnité ne pourra être réclamée par le ou les locataires.\n \n
                                    La perte ou la casse des clés de la piscine sera facturée 20€.
                                    Tout arrachage, ou dégradation des sangles piscine seront facturés 30€ par sangle.
                                    `} />
                            </AccordionBody>
                        </>
                    ) : null}

                </div>

                <InventoryFormSection title={"Signature"}>
                    <p><i>En signant ce document, vous reconnaissez avoir lu et approuvé toutes les informations et règles contenues dans ce document.<br />
                        Toutes règles non respectées donne l'autorisation au propriétaire de prélever une somme sur la caution.</i></p>

                    <h5>Signature du concierge : {user.name + " " + user.lastName}</h5>
                    <InventorySignature readOnly={required} signatureDefault={inv["entree-sign-concierge"]} inOrOut={"entree"} who={"concierge"} signaturer={user.name + " " + user.lastName} />

                    <h5 className="mt-5">Signature du locataire : {sel.booking.people.name}</h5>
                    <InventorySignature readOnly={required} signatureDefault={inv["entree-sign-locataire"]} inOrOut={"entree"} who={"locataire"} signaturer={sel.booking.people.name} signatureDefaultConsent={inv["entree-sign-consent"] || null} />

                </InventoryFormSection>

                <InventoryFormSection title={"Images"}>
                    <div className="mb-3 px-0">
                        <p className="inventory-notice">
                            Attention, ces images ne sont qu'un complément à l'état des lieux et ne doivent pas remplacer la saisie d'informations. <br />
                            Tout problème présent dans ces images doit être inscrit dans le champs correspondant de l'état des lieux.
                        </p>
                        <label htmlFor="files" className="form-label bold">Photos de votre interventions</label>
                        <p className={"small text-muted"}>Les photos ne sont pas obligatoires pour ce type d'intervention. <br /> Vos photos doivent être prises le jour même de
                            l'intervention et refléter le travail effectué.</p>
                        <input readOnly={required} disabled={required} name="in-images" id={"files"} type="file" multiple="multiple" accept="image/*"
                            onChange={imageChange} required={!/Check(-?| )?(out|in)/i.test(sel.description?.description)} />
                    </div>
                    <div>
                        <p>Images à envoyer : {imagesURLs?.length}</p>
                    </div>
                    <div className={"imagesList d-inline-flex"}>
                        {imagesURLs.map((url, index) => <img key={index} src={url} className={"ml-1"} alt={index} />)}
                    </div>
                    <div>
                        <p>Images déjà envoyées : {inv?.images?.length}</p>
                    </div>
                    <div className={"imagesList d-inline-flex"}>
                        {inv?.images?.map((url, index) => <img key={index} src={userContext.getBurl() + "/" + url} className={"ml-1"} alt={index} />)}
                    </div>
                </InventoryFormSection>

                <InventoryFormSection title={"Mail"}>
                    <p>Un mail vous sera envoyé contenant l'état des lieux de la propriété ainsi que la liste des règles de la propriété.</p>
                    <p>Veuillez indiquer la préférence du locataire quant à la réception de ce mail et vérifier avec le locataire que l'adresse mail est correcte :</p>
                    <InventoryFormRow>
                        <InventoryFormInput readOnly={required} defaultValue={inv["in-mail-consent"]} type={"check"} name={"in-mail-consent"} label={"Le locataire souhaite recevoir par mail l'état des lieux et les règles"} />
                    </InventoryFormRow>
                    <InventoryFormRow>
                        <InventoryFormInput defaultValue={user.email} name={"in-email-concierge"} label={"Email du concierge"} />
                        <InventoryFormInput readOnly={required} defaultValue={sel.booking.people.email} name={"in-email-locataire"} label={"Email du locataire"} />
                    </InventoryFormRow>
                </InventoryFormSection>
            </div>
        )
    } catch (e) {
        console.log(e)
        return <div>Erreur</div>
    }
}