import React from 'react';
import './App.scss';
import {
    BrowserRouter as Router,
    Route, Switch, Redirect
} from "react-router-dom";
import "jquery"
import {UserProvider} from "./userContext";
import "react-datepicker/dist/react-datepicker.css";

//ROUTES
import LoginRoute from "./Routes/LoginRoute";

//Layout
import Error from "./Layout/Error";

//Views
import Login from './Views/Login'
import Dashboard from "./Views/Dashboard";
import ResetPassword from "./Views/ResetPassword";
import SetNewPassword from "./Views/SetNewPassword";
import Creation from "./Views/Creation";

const baseURL = document.getElementsByTagName('base')[0].getAttribute('href');

function App() {
    return (
        <UserProvider>
            <Router forceRefresh={false}
                    basename={baseURL}>
                <div className="App">
                    <Switch>
                        <Route path={'/login'} exact component={Login}/>
                        <Route path={'/reset'} exact component={ResetPassword}/>
                        <Route path={'/resetPassword/:token'} exact component={SetNewPassword}/>
                        <Route path={'/creation/:token'} exact component={Creation}/>
                        <LoginRoute path={'/'} exact component={Dashboard}/>
                        <LoginRoute path={'/404'} component={Error}/>
                        <Redirect from='*' to='/404'/>
                    </Switch>
                </div>
            </Router>
        </UserProvider>
    );
}

export default App;