import React from 'react';
import axios from "axios";
import Cookies from 'js-cookie'

const UserContext = React.createContext();

let burl = "https://api2.mypacome-manager.fr"
// burl = "http://localhost:3010"
// burl = "http://192.168.1.22:3010"

//http://192.168.1.12:3010 http://localhost:3010 https://api.mypacome-manager.fr https://api2.mypacome-manager.fr


export class UserProvider extends React.Component {
    state = {
        user: {},
        token: null,
        data: {},
        loading: false,
        error: {}
    }

    header = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    headerAuth = () => {
        return {
            headers: {
                "Content-Type": "application/json",
                "token": this.state.token
            }
        }
    }
    headerAuthFiles = () => {
        return {
            headers: {
                "Content-Type": "multipart/form-data",
                "token": this.state.token
            }
        }
    }
    setUser = async (user, token) => {
        return new Promise(((resolve, reject) => {
            this.setState({ user: user, token: token }, (err) => {
                if (err) return reject(err)
                else return resolve()

            })
        }))
    }
    setData = (data) => {
        return new Promise(((resolve, reject) => {
            this.setState({ data: data }, (err) => {
                if (err) reject(err)
                else resolve()
            })
        }))
    }
    addData = (data) => {
        return new Promise(((resolve, reject) => {
            const newData = this.state.data
            for (const key in data) {
                for (const item in data[key]) {
                    const itemRefined = data[key][item]
                    itemRefined.type = key

                    //Check if element already exist, if so delete it
                    newData.list = newData.list.filter((i) => {
                        return !(
                            i.email === itemRefined.email
                            && i.type === itemRefined.type
                            && (!!itemRefined.id ? i.id === itemRefined.id : true)
                        )
                    })
                    newData.people[key] = newData.people[key].filter((i) => {
                        return !(
                            i.email === itemRefined.email
                            && (!!itemRefined.id ? i.id === itemRefined.id : true)
                        )
                    })

                    //Push new element
                    newData.list.push(itemRefined)
                    newData.people[key].push(data[key][item])
                }
            }
            this.setState({ data: newData }, (err) => {
                if (err) {
                    this.setError({ message: err })
                        .then(() => {
                            return reject(err)
                        })
                        .catch((err2) => {
                            return reject(err2)
                        })

                } else {
                    return resolve()
                }
            })
        }))
    }
    setLoading = async (value) => {
        return new Promise((((resolve, reject) => {
            this.setState({ loading: value }, (err) => {
                if (err) return reject(err)
                else return resolve()
            })
        })))
    }
    setError = async (err) => {
        return new Promise((((resolve, reject) => {
            this.setState({ error: err }, (err) => {
                if (err) return reject(err)
                else return resolve()
            })
        })))

    }
    searchIntervention = (id) => {
        for (const property in this.state.data) {
            const found = this.state.data[property].filter(item => item.items.some(subItem => subItem._id === id))
            if (found.length > 0) {
                const foundItem = found[0].items.filter(item => item._id === id)
                return { type: property, item: foundItem[0] }
            }
        }
        return { type: 'error', item: {} }
    }
    logout = () => {
        Cookies.remove('MyPacome-Concierge', { path: '/' })
        this.setState({ user: {}, token: '' })
    }
    isLogged = () => {
        return !!this.state.token
    }
    getBurl = () => {
        return burl
    }
    errorhandler = (err) => {
        console.log(err)
        switch (err.status) {
            case 400:
                return "400 : Problème dans la requête"
            case 401:
                this.logout()
                return "401 : Connexion expiré"
            case 402:
                return "402 : Problème dans la facturation"
            case 403:
                return "403 : Requête non autorisée"
            case 404:
                return "404 : Ressource non trouvée"
            case 405:
                return "405 : Methode d'acccès non autorisée"
            case 406:
                return "406 : Requête non acceptable"
            case 407:
                return "407 : Authentification par proxy nécessaire "
            case 408:
                return "408 : La requête prend trop de temps pour aboutir"
            case 409:
                return "409 : Conflit détecté"
            case 410:
                return "410 : Requête perdu"
            case 411:
                return "411 : Taille de la requête non précisée"
            case 412:
                return "412 : Requête non acceptée"
            case 413:
                return "413 : Requête trop volumineuse"
            case 414:
                return "414 : Entête de la requête trop volumineuse"
            case 415:
                return "415 : Type de média non supporté"
            case 500:
                return "500 : Erreur serveur"
            case 501:
                return "501 : Erreur serveur : non implémentée"
            case 502:
                return "502 : Serveur non atteignable "
            case 503:
                return "503 : Serveur surchargé"
            case 504:
                return "504 : Erreur serveur : La requête prend trop de temps pour aboutir "
            case 505:
                return "505 : Erreur serveur : version de HTTP non supporté"

            default:
                break
        }
    }

    apiReducer = (action, payload, option) => {
        return new Promise((resolve, reject) => {
            let isAnAction = false


            if (action === 'login') {
                isAnAction = true

                axios.post(
                    burl + '/concierge/login',
                    payload,
                    this.header
                )
                    .then((response) => {
                        const user = { ...response.data }
                        user.token = null
                        this.setUser(user, response.data.token)
                            .then(() => {
                                if (option.remember) {
                                    Cookies.set('MyPacome-Concierge', {
                                        user: user,
                                        token: response.data.token
                                    }, { path: '/' })
                                    resolve()
                                } else {
                                    Cookies.set('MyPacome-Concierge', {
                                        user: user,
                                        token: response.data.token
                                    }, { expires: 1, path: '/' })
                                    resolve()
                                }
                            })
                            .catch((err) => {
                                reject(err)
                            })
                    })
                    .catch((err) => {
                        reject(this.errorhandler(err.response))
                    })
            }
            if (action === 'reset') {
                isAnAction = true

                axios.post(
                    burl + '/concierge/reset/',
                    payload,
                    this.header
                )
                    .then((result) => {
                        resolve(result)
                    })
                    .catch((err) => {
                        reject(this.errorhandler(err.response))
                    })
            }
            if (action === 'setPassword') {
                isAnAction = true
                axios.put(
                    burl + '/concierge/reset/password/',
                    payload,
                    this.header
                )
                    .then((result) => resolve(result))
                    .catch((err) => {
                        reject(this.errorhandler(err.response))
                    })
            }
            if (action === 'creation') {
                // TODO: Check if working on V2.0.1
                isAnAction = true
                axios.post(
                    burl + '/concierge/creation/',
                    payload,
                    this.header
                )
                    .then((result) => {
                        resolve(result)
                    })
                    .catch((err) => {
                        reject(this.errorhandler(err.response))
                    })
            }
            if (action === 'fetchData') {
                isAnAction = true
                axios.get(
                    burl + '/concierge/all/',
                    this.headerAuth()
                )
                    .then((response) => {
                        const list = response.data.interventions.map(intervention => {
                            intervention.valide = intervention.people.some(people =>
                                people.people === this.state.user?.id &&
                                people.photo.length >= 0 &&
                                people.report &&
                                people.finishDate)

                            if (intervention.date.dateStart.includes("2023-09-26")) console.log(intervention)
                            return intervention
                        })
                        this.setData({
                            interventions: list,
                            properties: response.data.properties,
                            user: {
                                id: response.data.id,
                                name: response.data.name,
                                lastName: response.data.lastName,
                                email: response.data.email,
                                phone: response.data.phone
                            }
                        })
                            .then(() => {
                                resolve()
                            })
                            .catch((err) => {
                                reject(this.errorhandler(err.response))
                            })
                    })
                    .catch(err => {
                        reject(this.errorhandler(err.response))
                    })

            }
            if (action === 'signal') {
                isAnAction = true

                axios.post(
                    burl + '/intervention/' + option.id,
                    payload,
                    this.headerAuthFiles()
                )
                    .then((res) => {
                        const rep = this.apiReducer('fetchData')
                        this.setLoading(true)
                            .then(() => {
                                rep
                                    .then(() => {
                                        this.setLoading(false)
                                            .then(() => {
                                                return resolve(res)
                                            })
                                            .catch((err) => {
                                                return reject(err)
                                            })
                                    })
                                    .catch(err => {
                                        this.setLoading(false)
                                            .then(() => {
                                                return resolve(res)
                                            })
                                            .catch((err) => {
                                                return reject(err)
                                            })
                                    })
                            })
                            .catch(err => {
                                this.setLoading(false)
                                    .then(() => {
                                        return resolve(res)
                                    })
                                    .catch((err) => {
                                        return reject(err)
                                    })
                            })


                    })
                    .catch((err) => {
                        reject(this.errorhandler(err.response))
                    })

            }
            if (action === 'validate') {
                isAnAction = true
                axios.post(
                    burl + '/concierge/intervention',
                    payload,
                    this.headerAuthFiles()
                )
                    .then((res) => {
                        const rep = this.apiReducer('fetchData')
                        rep
                            .then(() => {
                                resolve(res)
                            })
                            .catch(err => {
                                reject(this.errorhandler(err.response))
                            })
                    })
                    .catch((err) => {
                        reject(this.errorhandler(err.response))
                    })
            }
            if (action === 'getHomeView') {
                isAnAction = true
                axios.get(burl + '/client/homeview/', this.headerAuthFiles())
                    .then((result) => {
                        this.addData({ Client: [result.data] })
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        this.errorHandler(err)
                        return reject(err)
                    })

            }

            if (!isAnAction) reject(`${action} is not a registered action in the reducer`)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.value !== prevState.value) {
            // TODO: Rework to get informations without connexion
            localStorage.setItem("parentValueKey", this.state.value)
        }
    }

    componentWillMount() {
        let cookie = Cookies.get('MyPacome-Concierge')
        if (cookie) {
            cookie = JSON.parse(cookie)
            this.setUser(cookie.user, cookie.token)
        }
    }

    render() {
        const { children } = this.props
        const { user, token, data, loading, error } = this.state
        const { logout, isLogged, getBurl, searchIntervention, apiReducer } = this

        return (
            <UserContext.Provider
                value={{ user, token, data, loading, error, apiReducer, searchIntervention, logout, isLogged, getBurl }}>
                {children}
            </UserContext.Provider>
        )
    }
}

function sort(items) {
    const list = []
    items.map((item) => {
        const dateStart = new Date(item.dateStart)
        const date = dateStart.toLocaleDateString("fr-FR", { year: 'numeric', month: 'long' })
        const beginDate = new Date(dateStart.getFullYear(), dateStart.getMonth(), 1)
        let indexFound
        list.some((obj, index) => {
            if (obj.date === date) {
                indexFound = index
                return true
            } else {
                return false
            }
        })
        if (indexFound !== undefined) {
            list[indexFound].items.push(item)
        } else {
            list.push({
                date: date,
                beginDate: beginDate,
                items: [item]
            })
        }
        return null
    })
    list.sort((a, b) => {
        return a.beginDate > b.beginDate ? -1 : a.beginDate < b.beginDate ? 1 : 0;
    })
    return list
}

export default UserContext
