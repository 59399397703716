import React from 'react';

import UserContext from "../userContext";

import Header from './Header'
import Footer from './Footer'
import Loading from "./Loading";

export function Layout(props) {
    const [loading, setLoading] = React.useState(true)
    const [first, setFirst] = React.useState(true)
    const [show, setShow] = React.useState(false)

    const userContext = React.useContext(UserContext)

    React.useEffect(() => {
        if (loading && first) {
            setFirst(false)
            userContext.apiReducer('fetchData')
                .then((result) => {
                    setLoading(false)
                })
        }
    }, [userContext, loading, setLoading, first, setFirst])

    return (
        <div id="content-wrapper" className="min-vh-100">
            <Header show={{ value: show, setValue: (value) => setShow(value) }} />
            <div className={"layoutContent"}>
                {
                    loading
                        ? <Loading />
                        : props.children
                }
            </div>
            <Footer />
        </div>
    )
}

export default Layout