import { useEffect, useState } from "react"
export default function InventoryFormInput({ label, readOnly, defaultValue, placeholder, type, date, size, title, note, name }) {
    if (type === "check") {
        return (
            <div className="custom-control custom-checkbox">
                <InventoryInput id={name} name={name} readOnly={readOnly} placeholder={placeholder} defaultValue={defaultValue} type={type} date={date} />
                {/* <input type="checkbox" className="custom-control-input" id="defaultUnchecked" /> */}
                <label className="custom-control-label" htmlFor={name}>{label}</label>
            </div>
        )
    }
    return (
        <>
            {title ? <h5 className="w-100 text-center">{title}</h5> : ""}
            {note ? <p className="w-100 text-center">{note}</p> : ""}
            <div className={`col-md-${size ? size : 5} form-group row`}>
                <label className="col-form-label form-control-label text-muted">{label}</label>
                <InventoryInput name={name} readOnly={readOnly} placeholder={placeholder} defaultValue={defaultValue} type={type} date={date} />
            </div>
        </>
    )
}

function InventoryInput({ readOnly, placeholder, type, date, defaultValue, name, label }) {
    if (!defaultValue) defaultValue = ""
    const [value, setValue] = useState(defaultValue)
    if (!value && date && value !== date) setValue(date) // To have a default date but still able to change it
    useEffect(() => {
    }, [value]); // Make sure the value is re-rendered properly ... react :)
    switch (type) {
        case 'date':
            const dateTemp = new Date(value)
            let monthTemp = dateTemp.getMonth() + 1;
            monthTemp < 10 ? monthTemp = '0' + monthTemp : monthTemp = '' + monthTemp;
            let dateDayTemp = dateTemp.getDate();
            dateDayTemp < 10 ? dateDayTemp = '0' + dateDayTemp : dateDayTemp = '' + dateDayTemp;
            const dateString = `${dateTemp.getFullYear()}-${monthTemp}-${dateDayTemp}`;
            return <input name={name} readOnly={readOnly} className="form-control" type="date" value={dateString} onChange={((e) => setValue(e.target.value))} />

        case 'dropdownNumber':
            const numberOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
            return (
                <select readOnly={readOnly} required name={name} value={value} onChange={((e) => setValue(e.target.value))} className="form-control" aria-label="Choisissez le nombre">
                    {
                        numberOptions.map((option, index) => {
                            return <option key={index} value={option}>{option}</option>
                        })
                    }
                </select>
            )

        case 'textarea':
            return <textarea readOnly={readOnly} name={name} onChange={((e) => setValue(e.target.value))} className="form-control w-100" value={value}></textarea>

        case 'dropdownColour':
            let bgColour
            switch (parseInt(value)) {
                case 0:
                    bgColour = '#ffffff' + (readOnly ? "40" : "")
                    break
                case 3:
                    bgColour = '#ff9f00' + (readOnly ? "40" : "")
                    break
                case 4:
                    bgColour = '#ffff00' + (readOnly ? "40" : "")
                    break
                case 5:
                    bgColour = '#00ff00' + (readOnly ? "40" : "")
                    break
                default:
                    bgColour = '#ffffff' + (readOnly ? "40" : "")
                    break;
            }
            return (
                <select disabled={readOnly} readOnly={readOnly} required name={name} style={{ backgroundColor: bgColour }} value={value} onChange={((e) => setValue(e.target.value))} className="form-control select-colour" aria-label="Choisissez le nombre">
                    <option value={""} style={{ backgroundColor: '#ffffff' }}>Séléctionner une note</option>
                    <option value={5} style={{ backgroundColor: '#00ff00' }}>Très bien</option>
                    <option value={4} style={{ backgroundColor: '#ffff00' }}>Bien</option>
                    <option value={3} style={{ backgroundColor: '#ff9f00' }}>Moyen</option>
                </select>
            )

        case 'check':
            // This ended up being a whole mess, not sure why its so weird, but im doing a lot of string to boolean by checking if the string is "true", so it returns true or false
            if (typeof value === 'undefined' || value === "") setValue(false)
            else if (typeof value === "string") setValue(value === "true")
            return <input readOnly={readOnly} disabled={readOnly} id={name} type="checkbox" name={name} placeholder={placeholder} className="custom-control-input" value={value} checked={value} onChange={((e) => setValue(!(e.target.value === "true")))} />


        default:
            return <input readOnly={readOnly} name={name} required placeholder={placeholder} className="form-control" type="text" value={value} onChange={((e) => setValue(e.target.value))} />
    }
}