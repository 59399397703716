import React from "react";

// context

import userContext from '../../../userContext'

export default function HomeDetailsv2(props) {
    const context = React.useContext(userContext)
    const burl = context.getBurl()

    let actualHome = props.sel.property
    let homeData = props.data

    console.log(homeData && homeData.properties.map((property) => property.name).includes(actualHome))
    if (homeData && homeData.properties.map((property) => property.name).includes(actualHome)) {
        const home = homeData.properties.find((property) => property.name === actualHome);
        let list = JSON.parse(home.homeViewNew)
        if (typeof list === "string") {
            list = JSON.parse(list)
        }
        return (
            <>
                <form className={"home-view-add-container"} >
                    <div className={"home-view-header"}>
                    </div>
                    {Array.isArray(list) && list.map((item, indexCategory) => {
                        const images = item.images?.map((image) => {
                            return burl + "/uploads/" + image
                        })
                        return (
                            <div className={"section"} key={indexCategory}>

                                <div className="input-title">
                                    <div className="area">
                                        <input name={"title"} type={"text"}
                                            defaultValue={item.title} disabled />
                                    </div>

                                </div>
                                {item.content?.map((item, indexSection) => {

                                    return (
                                        <div className="category">
                                            <hr></hr>
                                            <p className="name text-center fw-bold">{item.title}</p>
                                            {item.type === "text" ? (
                                                item.fields?.map((field, indexSingle) => {
                                                    // if (field.dependsOn !== "" && typeof field.dependsOn !== 'undefined') {
                                                    //     console.log(field.dependsOn, "dependsOn")
                                                    //     console.log(field, "field")
                                                    // }
                                                    if (field.type === "check") {
                                                        switch (field.value) {
                                                            case 'false':
                                                                field.value = false
                                                                break;
                                                            case 'true':
                                                                field.value = true
                                                            default:
                                                                break;
                                                        }
                                                    }
                                                    return (
                                                        <div className={"input type-" + field.type}>
                                                            <div className="icon">{field.title}</div>
                                                            <div className="area" style={{ width: "auto" }}>
                                                                {field.type === "input" ? (
                                                                    <textarea
                                                                        name={"text"}
                                                                        value={field.value}
                                                                        disabled
                                                                    />
                                                                ) : field.type === "textarea" ? (
                                                                    <textarea
                                                                        name={"textarea"}
                                                                        value={field.value}
                                                                        disabled
                                                                        style={{ resize: "none" }}
                                                                    />

                                                                ) : field.type === "check" ? (
                                                                    <input
                                                                        type='checkbox'
                                                                        name='check'
                                                                        value={field.value}
                                                                        disabled
                                                                        checked={field.value ? true : false}
                                                                        className="form-checkbox"
                                                                    />


                                                                ) : (
                                                                    <select
                                                                        className="form-control h-100"
                                                                        name={"dropdown"}
                                                                        value={field.value}
                                                                        disabled
                                                                    >
                                                                        {field.options?.map((option) => (
                                                                            <option value={option}>{option}</option>
                                                                        ))}
                                                                    </select>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )


                                                })
                                            ) : (
                                                <div className="section-check">
                                                    <input
                                                        type='checkbox'
                                                        name='check'
                                                        value={item.value}
                                                        disabled
                                                        checked={item.value ? true : false}
                                                        className="form-checkbox"
                                                    />

                                                    {
                                                        item.fields?.map((field, indexSingle) => {
                                                            let cssClass
                                                            item.value ? cssClass = "" : cssClass = " d-none"
                                                            return (

                                                                <div className={"input type-" + field.type + cssClass}>
                                                                    <div className="icon">{field.title}</div>
                                                                    <div className="area" style={{ width: "auto" }}>
                                                                        {field.type === "input" ? (
                                                                            <textarea
                                                                                name={"text"}
                                                                                disabled
                                                                                value={field.value}
                                                                            />
                                                                        ) : field.type === "textarea" ? (
                                                                            <textarea
                                                                                name={"textarea"}
                                                                                disabled
                                                                                value={field.value}
                                                                                style={{ resize: "none" }}
                                                                            />
                                                                        ) : field.type === "check" ? (
                                                                            <input
                                                                                type="checkbox"
                                                                                name={"check"}
                                                                                disabled
                                                                                value={field.value}
                                                                                className="form-checkbox"
                                                                            />
                                                                        ) : (
                                                                            <select
                                                                                className="form-select"
                                                                                name={"dropdown"}
                                                                                disabled
                                                                                value={field.value}
                                                                            >
                                                                                {field.options?.map((option) => (
                                                                                    <option value={option}>{option}</option>
                                                                                ))}
                                                                            </select>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                            )

                                                        })
                                                    }

                                                </div>

                                            )}
                                        </div>
                                    );
                                })}

                                {/* <div className="input">
                                    <div className="name text-center fw-bold">Photos</div>
                                    <div className="area">
                                        <div hidden={!images || images?.length === 0} className={"home-view-image-container col"} >
                                            <Gallery images={images} />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="form-group multi-preview">
                                    <div className="row text-center text-lg-start">
                                        {images?.map((url, index) => (
                                            <div className="col-sm-6">
                                                <div className="image-container">
                                                    <img src={url} alt="..." className="img-fluid img-thumbnail" />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        )
                    })}
                    {/* <div className={"section"}>
                        <button className={"btn-add"} type={"button"} onClick={() => {
                            addSection()
                        }}>Ajouter une nouvelle section
                        </button>
                    </div> */}
                </form>
            </>
        )

        // return (
        //     <>
        //         {
        //             home.homeViewNew??.map(data => {
        //                 const title = data.title
        //                 const text = data.text
        //                 const images = data.images?.map((image) => {
        //                     return burl + "/uploads/" + image
        //                 });
        //                 const formattedText = text.split('\n').map((line, index) => <p key={index}>{line}</p>);
        //                 return (
        //                     <HomeViewCardv2 title={title} images={images} text={formattedText} />
        //                 )
        //             })
        //         }
        //     </>
        // )

        return (
            <div>erreur</div>
        )
    }
}