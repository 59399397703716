import React from "react"

import {BsX} from "react-icons/bs";
import {AiOutlineArrowRight, AiOutlineArrowLeft} from "react-icons/ai";

export default function Gallery(props) {
    const [focused, setFocused] = React.useState(-1)
    const [imageURL] = React.useState(props.images ? props.images[0] : null)

    const close = () => {
        setFocused(-1)
    }

    return (
        <>
            <img src={imageURL} className={"home-view-individual-image"} alt={"Gallerie"}/>
            <h3 onClick={() => {
                setFocused(0)
            }}><span hidden={!props.images || props.images?.length <= 1}>+{String(props.images ? props.images.length - 1 : null)}</span></h3>
            <GalleryLightbox
                hidden={focused === -1}
                close={() => close()}
                focused={focused} images={props.images}/>
        </>

    )
}

function GalleryLightbox(props) {
    const imgRef = React.useRef()
    const [currentFocus, setCurrentFocus] = React.useState(() => {
        return props.focused
    })
    const [,r] = React.useState({})

    const reload = function () {r({})}

    const editFocus = (value) => {
        setCurrentFocus(value)
    }

    React.useEffect(() => {
        if (props.focused !== -1) setCurrentFocus(props.focused)
    }, [props, setCurrentFocus])

    if (props.focused === -1 || props.focused === undefined) {
        return null
    }

    return (
        <div className="lightbox" onClick={(e) => {
            if (e.currentTarget !== e.target) {
                e.stopPropagation()
                return null
            }
            props.close()
        }}>
            <span className="close cursor" onClick={() => {
                props.close()
            }}><BsX/></span>
            <div className="lightbox-content">
                <img src={props.images[currentFocus]} alt={'Selectionnée'}
                     ref={imgRef}
                     onLoad={() => {
                         reload()
                     }}/>
                <div className={"lightbox-img-overlay"} style={{width: imgRef.current?.width, left: imgRef.current?.offsetLeft}}>
                    <div className="number-text">{currentFocus + 1} / {props.images?.length} </div>
                    <a hidden={currentFocus === 0} className="prev" onClick={() => {
                        editFocus(currentFocus - 1)
                    }}><AiOutlineArrowLeft/></a>
                    <a hidden={currentFocus === (props.images.length - 1)} className="next" onClick={() => {
                        editFocus(currentFocus + 1)
                    }}><AiOutlineArrowRight/></a>
                </div>


            </div>

            <div className={"lightbox-row row gx-0 gy-0"}>
                {props.images?.map((image, index) => {
                    if (index === currentFocus) return (
                        <img src={image} alt="Nature"
                             className={"active"} key={index}/>
                    )
                    return (
                        <img src={image}
                             onClick={() => {
                                 editFocus(index)
                             }}
                             alt="Nature" key={index}/>
                    )
                })}
            </div>


        </div>
    )
}

