import SignatureCanvas from "react-signature-canvas";
import { Button, Modal } from 'react-bootstrap'
import { useRef, useEffect, useState, useContext } from "react"
import UserContext from "../../../../../userContext";

export default function InventorySignature({ signatureDefault, signaturer, who, inOrOut, readOnly, signatureDefaultConsent }) {
    const userContext = useContext(UserContext)

    const sigPad = useRef()
    const [showModal, setShowModal] = useState(false)

    const handleShow = () => setShowModal(true)

    const confirmSignature = () => {
        const signatureImage = sigPad.current.getTrimmedCanvas().toDataURL('image/png')
        setSignature(signatureImage)
        setSignatureConsent(true)
    }

    const handleClose = () => {
        setShowModal(false)
        confirmSignature()
    }

    const handleCloseCancel = () => {
        setShowModal(false)
    }

    signatureDefault = signatureDefault || ""

    const [signature, setSignature] = useState(signatureDefault)
    const [signatureConsent, setSignatureConsent] = useState(signatureDefaultConsent || true)
    useEffect(() => {
    }, [signature]); // Re-render when signature changes
    return (
        <div>
            <div className="signatures mt-3">
                {
                    signature
                        ? <img className={"signature"} alt='signature'
                            src={signature.startsWith('data:image') ? signature : userContext.getBurl() + "/" + signature} />
                        : null
                } <br />
                <input value={signature} name={inOrOut + "-sign-" + who} type="hidden" />
                <input type="hidden" name={inOrOut + "-sign-consent"} value={signatureConsent} />
                <input readOnly className="hiddenInput" type="text" defaultValue={signature === "" ? "" : "Une signature est renseignée"} required />
                <Button hidden={readOnly} className=" montserrat" variant="primary" onClick={handleShow}>
                    {signature ? "Modifier" : "Ajouter"} la signature de <b>{signaturer}</b>
                </Button>
                {/* Add a check to say that the renter does not wish to sign, which whill  */}
                {who === "locataire" ? (
                    <Button hidden={readOnly} className="mb-4 mt-2 montserrat" variant="primary" onClick={() => { setSignature(defaultSignature); setSignatureConsent(false) }} ><i>Le locataire ne souhaite pas signer</i></Button>) : null}
            </div>


            <Modal show={showModal} onHide={handleClose} id="modal1">
                <Modal.Header closeButton>
                    <Modal.Title>{signature ? "Modifier" : "Ajouter"} la signature de {signaturer}</Modal.Title>
                </Modal.Header>
                <Modal.Body> <SignatureComponent sigPad={sigPad} setSignature={setSignature} /> </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCancel}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Valider la signature
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

function SignatureComponent({ setSignature, sigPad }) {

    // all this just to make the canvas full width
    const containerRef = useRef()
    const [canvasSize, setCanvasSize] = useState({ width: 0 })
    const getCanvasSize = () => {
        const container = containerRef.current

        // The -10 is for the border of 5px
        const width = container.offsetWidth - 10

        return { width }
    }
    useEffect(() => {
        const handleResize = () => {
            setCanvasSize(getCanvasSize())
        }

        handleResize()

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const clearSignature = () => {
        sigPad.current.clear()
    }

    return (
        <div>
            <div ref={containerRef}>
                <SignatureCanvas penColor='black'
                    canvasProps={{ className: "sigPad", ...canvasSize, height: 300 }}
                    ref={sigPad}
                />
            </div>


            <Button className="mt-0 w-100" variant="secondary" onClick={clearSignature}>
                Effacer la signature
            </Button>
        </div>
    )
}

const defaultSignature = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAaVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD///8n0tSfAAAAIXRSTlMAAxESExUWFxgZdnh6nJ6g0tPU1dbX2N3e3+Hj6uvs7e4EczevAAAAAWJLR0QiXWVcrAAAAflJREFUaN7l2tlygkAQBdBGIi64gZpEs9n//5N5iOww6728xDerpE51wUz3XJFkd38cUiF9Xo6Pe57ITlX1vOIYq4uqai4fqqr6umEYWamqqjf5UWXV8leH6qcclKVUhu4lPZOU2igXIlnx/IK9L8/7oXrdiJCUnkFRBgZBGTHgyqgBViYMqDJpABWDAVOMBkixGBDFagCUxlgbfhSnOBmRiqMRpTgbEYqHEax4GYGKp9FSrs5Kc8na/5INzfBWggyR7OShBBoiS3cl2PBQIgxnJcpwVCINJyXacFAAhlWBGBYFZBgVmGFQgMakAjUmFLAxqsCNEYVgDBSK0VNIRkehGa1xpKwO/kWGDzHqWmh1DBWO0VVYRlvhGc2z6zFbhhs8pW2wlK7BUVb1GqxXZUar47penki1dPYrktLbEynKYN8lKCN7O1wZ7R9gZaJHQZXJPlgrgKxvutfCFGM/BymWmWEJyfpscwki67PPPvFZXzFD1lfMkPUVM2R9xQxZX/Hfsz53xdsIUAIMbyXI8FQCDS8l2PBQIgxnJcpwVCINJyXacFAAhlWBGBYFZBgVmGFQHP4fjFagxoQCNkYVuDGiEIyBQjF6SnohHcvrg/85lSPt6F8rh+qFpZKQ9VXKl9z5ccyb5Kw6mlq2kuS3r/2CFZGlh+/3bfILdtnSFWB4N14AAAAASUVORK5CYII="