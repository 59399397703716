import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (

            <footer className="footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright © MyPacome 2023</span>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer