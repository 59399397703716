export default function AccordionCard({ title, content }) {
    const slugify = (text) => {
        return text
            .toString()                   // Cast to string (optional)
            .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
            .toLowerCase()                // Convert the string to lowercase letters
            .trim()                       // Remove whitespace from both sides of a string (optional)
            .replace(/\s+/g, '-')         // Replace spaces with -
            .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
            .replace(/\_/g, '-')           // Replace _ with -
            .replace(/\-\-+/g, '-')       // Replace multiple - with single -
            .replace(/\-$/g, '');         // Remove trailing -
    }
    const slugTitle = slugify(title)
    // If there are any \ns in the content, split the content into an array of paragraphs
    if (content.includes("\n")) {
        content = content.split("\n").map((paragraph, index) => {
            return <p key={index}>{paragraph}</p>
        })
    }
    return (
        <div className="card">
            <div className="card-header" id={slugTitle}>
                <h2 className="mx-0 my-0">
                    <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target={`#collaspe${slugTitle}`} aria-expanded="true" aria-controls="collapseOne">
                        {title}
                    </button>
                </h2>
            </div>
            <div id={"collaspe" + slugTitle} className="collapse" aria-labelledby={slugTitle} data-parent="#accordionExample">
                <div className="card-body">
                    {content}
                </div>
            </div>
        </div>
    )
}