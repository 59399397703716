import React from "react";
import Gallery from "../Sub-Components/Gallery";


export default function HomeViewCard(props) {

    return (
        <div>
            <h2 hidden={!props.title}>{props.title}</h2>
            <div>
                <div hidden={!props.images || props.images?.length === 0}>
                    <Gallery images={props.images} />
                </div>
                <p hidden={!props.text} className={"flex-grow-1 col"}>{props.text}</p>
            </div>
            <hr/>
        </div>

    )
}



