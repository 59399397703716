import { FiSend } from "react-icons/fi";
import SubmitButton from "../../Sub-Components/Submit-button";
export default function ValidateDefault({ sel, imageChange, imagesURLs, loading }) {
    return (
        <div>
            <div className="mb-3 px-2">

                <label htmlFor="report" className="form-label bold">Rapport de fin
                    d'intervention</label>
                <textarea className="form-control" id="report" rows="3" required />
                <p className={"small text-muted"}>Inscrivez ici votre ce que vous avez fait, vos
                    remarques, ainsi que les problèmes rencotrés s'il y en a eu.</p>
            </div>
            <hr />
            <div className="mb-3 px-2">
                <label htmlFor="files" className="form-label bold">Photos de votre interventions</label>
                {
                    // If its a check-in or a check-out, tell people they dont have to put pictures 
                    /Check(-?| )?(out|in)/i.test(sel.description?.description) ?

                        <p className={"small text-muted"}>Les photos ne sont pas obligatoires pour ce type d'intervention. <br /> Vos photos doivent être prises le jour même de
                            l'intervention et refléter le travail effectué.</p> :
                        <p className={"small text-muted"}>Vos photos doivent être prises le jour même de
                            l'intervention et refléter le travail effectué.</p>
                }
                <input id={"files"} type="file" multiple="multiple" accept="image/*"
                    onChange={imageChange} required={!/Check(-?| )?(out|in)/i.test(sel.description?.description)} />
            </div>
            <div>
                <p>Nombre d'images : {imagesURLs.length}</p>
            </div>
            <div className={"imagesList d-inline-flex"}>
                {imagesURLs.map((url, index) => <img key={index} src={url} className={"ml-1"} alt={index} />)}
            </div>
        </div>
    )
}