import React, { useState } from 'react'
import UserContext from "../userContext"
import "../Styles/header.scss"
import logo from '../images/mypacomefavicon.svg'
import { Button, Modal } from 'react-bootstrap'
import { BsWifiOff } from 'react-icons/bs'

export default function Header(props) {
    const userContext = React.useContext(UserContext)
    const [showModal, setShowModal] = useState(false)

    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true)
    let localInventory = JSON.parse(localStorage.getItem('savedFormData'))
    console.log(localInventory)
    if (localInventory?.inventory) {
        localInventory = JSON.parse(localInventory.inventory)
    }

    const validateAndSendInventory = async () => {
        let localInventory = JSON.parse(localStorage.getItem('savedFormData'));
        if (localInventory.inventory) {
            localInventory.inventory = JSON.parse(localInventory.inventory);

            if (navigator.onLine) {
                try {
                    const fd = new FormData();
                    console.log('localInventory', localInventory)
                    fd.append('inventory', JSON.stringify(localInventory.inventory));
                    fd.append('id', localInventory.id);
                    if (localInventory.report) {
                        fd.append('report', localInventory.report);
                    }
                    if (localInventory.files) {
                        for (let i = 0; i < localInventory.files.length; i++) {
                            fd.append('files', localInventory.files[i]);
                        }
                    }

                    await userContext.apiReducer('validate', fd);

                    localStorage.removeItem('savedFormData');
                    alert("L'état des lieux a bien été envoyé !");
                    window.location.reload();
                } catch (error) {
                    console.error("Erreur pendant l'envoi de l'état des lieux :", error);
                }
            } else {
                alert('Vous êtes hors ligne. Veuillez réessayer quand vous serez en ligne.');
            }
        } else if (localInventory.report) {
            if (navigator.onLine) {
                try {
                    const fd = new FormData();
                    fd.append('report', localInventory.report);
                    fd.append('id', localInventory.id);
                    if (localInventory.files) {
                        for (let i = 0; i < localInventory.files.length; i++) {
                            // Convert the base64 string back to a Blob
                            const byteCharacters = atob(localInventory.files[i].content.split(',')[1]);
                            const byteArrays = [];

                            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                                const slice = byteCharacters.slice(offset, offset + 512);
                                const byteNumbers = new Array(slice.length);
                                for (let i = 0; i < slice.length; i++) {
                                    byteNumbers[i] = slice.charCodeAt(i);
                                }
                                const byteArray = new Uint8Array(byteNumbers);
                                byteArrays.push(byteArray);
                            }

                            const blob = new Blob(byteArrays, { type: localInventory.files[i].type });
                            const file = new File([blob], localInventory.files[i].name, { type: localInventory.files[i].type });
                            fd.append('files', file);
                        }
                    } else {
                        fd.append('files', []);
                    }

                    // console.log('fd', fd)
                    await userContext.apiReducer('validate', fd);

                    localStorage.removeItem('savedFormData');
                    alert("Le rapport a bien été envoyé !");
                    window.location.reload();
                } catch (error) {
                    console.error("Erreur pendant l'envoi du rapport :", error);
                }
            } else {
                alert('Vous êtes hors ligne. Veuillez réessayer quand vous serez en ligne.');
            }
        }
    };


    return (
        <>
            <nav className="navbar navbar-expand navbar-light bg-white topbar static-top shadow">

                <ul className="navbar-nav">
                    <li>
                        <Button hidden={!localInventory} variant="primary" onClick={handleShow}>
                            <BsWifiOff /> États des lieux en attente d'envoi
                        </Button>
                    </li>
                    <li className="nav-item">


                        <Modal show={showModal} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>États des lieux en attente d'envoi</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Voici la liste des états des lieux en attente d'envoi</p>
                                <ul>
                                    <li hidden={!localInventory?.entree}>
                                        {localInventory?.entree && localInventory?.entree['in-start']} : <br /> {localInventory?.entree && localInventory?.entree['in-property-address']}
                                    </li>
                                    <li hidden={!localInventory?.sortie}>
                                        {localInventory?.sortie && localInventory?.sortie['out-start']} : <br /> {localInventory?.sortie && localInventory?.sortie['out-property-address']}
                                    </li>
                                    <li hidden={!localInventory?.report}>
                                        {localInventory?.report && localInventory?.report}
                                    </li>
                                </ul>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Fermer
                                </Button>
                                <Button variant="primary" onClick={validateAndSendInventory}>
                                    Envoyer
                                </Button>
                            </Modal.Footer>

                        </Modal>
                    </li>
                    {/* <div className="topbar-divider d-none d-sm-block" /> */}

                    <li className="nav-item dropdown no-arrow">

                        <div className="nav-link dropdown-toggle" id="userDropdown" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                {userContext.user?.lastName} {userContext.user?.name}
                            </span>
                            <img className="img-profile"
                                src={logo}
                                alt={"logo"} />
                        </div>

                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                            aria-labelledby="userDropdown">
                            <button className="dropdown-item" disabled>
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                                Profil <small>- Indisponible</small>
                            </button>
                            <button className="dropdown-item" disabled>
                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400" />
                                Paramètres <small>- Indisponible</small>
                            </button>
                            <div className="dropdown-divider" />
                            <button className="dropdown-item" data-toggle="modal" data-target="#logoutModal"
                                onClick={() => {
                                    userContext.logout()
                                }}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                                Déconnexion
                            </button>
                        </div>
                    </li>

                </ul>

            </nav>
        </>
    )
}