import React from "react";
import UserContext from "../userContext";

// External components
import Calendar from "../Layout/Components/Elements/Calendar";

export default function Dashboard() {
    const userContext = React.useContext(UserContext)

    if (userContext.loading) {
        return (
            <div className={"container-fluid"}>
                <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                    <div className={"align-items-center text-sm-left"}>
                        <h1>Chargement en cours</h1>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"container-fluid"}>
            <div className={"d-sm-flex align-items-center justify-content-between mb-4"}>
                <div className={"align-items-center text-sm-left"}>
                    <Calendar data={userContext.data?.interventions} id={userContext.user?.id} scope={"week"}/>
                </div>
            </div>
        </div>
    )
}