import React from "react";


export default function SubmitButton(props) {
    const loading = props.loading === "true"
    if (props.additionnalClass) {
        return (
            <button className={"btn btn-primary btn-user btn-block " + props.additionnalClass} {...props}
                type={"submit"}>
                {props.children}
            </button>
        )
    }
    if (!loading) {
        return (
            <button className={"btn btn-primary btn-user btn-block"} {...props}
                type={"submit"}>
                {props.children}
            </button>
        )
    } else {
        return (
            <div className={"btn btn-primary btn-user btn-block"} {...props}>
                <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden"> </span>
                </div>
            </div>
        )
    }
}
