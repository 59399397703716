import React from 'react'
import InterventionList from "./InterventionList";

const weekDays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', "Vendredi", "Samedi", "Dimanche"]

export default function Calendar(props) {
    const [current, setCurrent] = React.useState(new Date())
        , [scope, setScope] = React.useState('month')
        , [monthList, setML] = React.useState([])
        , [inters, setInters] = React.useState([])
        , [selected, setSelected] = React.useState([])
        , [today] = React.useState(new Date())

    React.useEffect(() => {
        if (monthList.length === 0) {
            const month = current.getMonth()
            const date = new Date(current.getFullYear(), current.getMonth(), 1)
            let days = [];
            const byWeeks = [[]]
            const interventionsToDisplay = []

            const getMonday = function (d) {
                let currentDateObj = new Date(d);
                currentDateObj.setDate(currentDateObj.getDate() - (currentDateObj.getDay() + 6) % 7);
                return currentDateObj
            }

            const getNextSunday = function (d) {
                var dayOfWeek = 0;//Sunday
                var currentDateObj = new Date(d);
                //if (currentDateObj.getDate() === 0) return currentDateObj
                currentDateObj.setDate(currentDateObj.getDate() + (dayOfWeek + 7 - currentDateObj.getDay()) % 7);
                return currentDateObj
            }

            // creating days of the week before first day of the month
            let firstofStartweek = new Date(getMonday(date))

            while (firstofStartweek < date) {
                days.push(new Date(firstofStartweek.getFullYear(), firstofStartweek.getMonth(), firstofStartweek.getDate()));
                firstofStartweek.setDate(firstofStartweek.getDate() + 1);
            }

            // creating days inside the month
            while (date.getMonth() === month) {
                days.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }

            // creating days of the week after last days of the month

            const endOfEndWeek = new Date(getNextSunday(date))
            while (date <= endOfEndWeek) {
                days.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }

            props.data.map((intervention) => {
                let start = new Date(intervention.date.dateStart)
                start = new Date(start.getFullYear(), start.getMonth(), start.getDate())
                if (days.some(el => {
                    return new Date(el).getTime() === start.getTime()
                })) {
                    interventionsToDisplay.push(intervention)
                }
            })

            let i = 0
            for (const day of days) {
                if (day.getDay() === 1) {
                    byWeeks.push([])
                    i++
                }
                byWeeks[i].push(day)
            }
            setML(byWeeks)
            setInters(interventionsToDisplay)


        }
    }, [monthList, setML, current, setInters])

    const changeCurrentMonth = (toAdd) => {
        let year = current.getFullYear()
            , month = current.getMonth()
            , date = current.getDate()

        if (scope === "month") {
            month += toAdd
        }
        if (scope === "day") {
            date += toAdd
        }
        if (scope === "week") {
            date += toAdd * 7
        }


        setML([])
        setCurrent(new Date(year, month, date))
    }

    const toDay = (date) => {
        const tmpDate = new Date(date)
        return tmpDate.getFullYear() + '/' + tmpDate.getMonth() + '/' + tmpDate.getDate()
    }

    if (scope === 'week') {
        return (
            <ul>{monthList.map((day, index) => {
                return (
                    <li key={index}>{day.toString('ddd')}</li>
                )
            })}</ul>
        )
    }

    if (scope === 'month') {
        return (
            <>
                <section id="calendar">
                    <div className={"day-warper justify-content-between d-flex"}>
                        <button className={"btn"} onClick={() => {
                            changeCurrentMonth(-1)
                        }}>{"<-"}</button>
                        <div
                            className={"label text-capitalize text-center"}>{current.toLocaleString('default', { month: 'long' })}</div>
                        <button className={"btn"} onClick={() => {
                            changeCurrentMonth(1)
                        }}>{"->"}</button>
                    </div>
                    <div id="day-labels">
                        {weekDays.map((day, index) => {
                            return (<div key={index} className="label">{day.substring(0, 3)}</div>)
                        })}
                    </div>

                    {monthList.map((weeks, index) => {
                        return (
                            <div key={index} id={index.toString()} className="week">
                                {
                                    weeks.map((day, index) => {

                                        const hasInter = inters.filter(el => {
                                            let start = new Date(el.date.dateStart)
                                            start = new Date(start.getFullYear(), start.getMonth(), start.getDate())
                                            return start.getTime() === new Date(day).getTime()
                                        })
                                        let classToAdd = "day"
                                        let classLabel = toDay(today) === toDay(day) ? "date today" : "date"

                                        if (current.getMonth() !== day.getMonth()) classToAdd = "day noDate"

                                        // if the is an intervention that day display it
                                        if (hasInter.length > 0) {

                                            //Display the total duration of all interventions this day
                                            //also show if the interventions are all validated or not
                                            let totalValidation = []
                                            let totalDuration = hasInter.map(inter => {
                                                const arr = inter.date.duration.split(':');
                                                const dec = parseInt((arr[1] / 6) * 10, 10);

                                                if (inter.valide) totalValidation.push(inter)
                                                return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec)

                                            }).reduce((partialSum, a) => partialSum + a, 0)

                                            const minutes = Math.round((totalDuration % 1) * 60)
                                            const minutesString = minutes < 10 ? '0' + minutes.toString() : minutes.toString()
                                            totalDuration = (~~(totalDuration / 1)).toString() + "H" + minutesString
                                            if (totalValidation.length == hasInter.length) classToAdd = [classToAdd, 'validated'].join(' ')
                                            else if (day < today) classToAdd = [classToAdd, 'error'].join(' ')
                                            else classToAdd = [classToAdd, 'inter'].join(' ')


                                            return (

                                                <div key={index} className={classToAdd} onClick={() => {
                                                    setSelected({
                                                        interventions: hasInter,
                                                        header: day.toLocaleDateString()
                                                    })
                                                }}>
                                                    <span className={classLabel}>{day.getDate()}</span>
                                                    <span className={"interInfo"}>{totalDuration}</span>
                                                </div>
                                            )
                                        }
                                        return (
                                            <div className={classToAdd}>
                                                <span className={classLabel}>{day.getDate()}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })}
                </section>
                <InterventionList data={selected?.interventions} header={selected?.header} close={() => {
                    setSelected({})
                }} />
            </>
        )
    }

    return (<p>No scope</p>)
}