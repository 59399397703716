import React, { useState, useContext } from 'react'
import UserContext from "../../../userContext";

// Internal Components
import SubmitButton from "../Sub-Components/Submit-button";
import HomeDetails from "./HomeDetails";
import HomeDetailsv2 from './HomeDetailsv2';
import HomeViewPDF from './HomeView/HomeViewPDF';
import HomeViewNewPDF from './HomeView/HomeViewNewPDF';
// External Components
import Compressor from 'compressorjs';

// Images
import altImg from "../../../images/imgNotFound.png";

// Icons
import { FiMap } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import { FiSend } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";
import ValidateDefault from './ValidateIntervention/ValidateDefault';
import ValidateInventoryCheckIn from './ValidateIntervention/ValidateInventoryCheckIn';
import ValidateInventoryCheckOut from './ValidateIntervention/ValidateInventoryCheckOut';
import { useRef } from 'react';
import { useEffect } from 'react';


export default function InterventionList(props) {
    const overlayRef = React.useRef()
    const userContext = React.useContext(UserContext)

    const [loading, setLoading] = React.useState(false)

    const [sel, setSel] = React.useState({}) // selected intervention

    const [validation, setValidation] = React.useState(false) //to validation
    const [report, setReport] = React.useState(false)// to report
    const [showHomeDetails, setShowHomeDetails] = useState(false); // to show home details
    const [showHomeDetailsv2, setShowHomeDetailsv2] = useState(false); // to show home details v2

    const [images, setImages] = React.useState([])// image list
    const [imagesURLs, setImagesURLs] = React.useState([])// image local url to display

    // 'Restrict' new inventory functionality to a select few concierges:
    // const inventoryConcierges = [
    //     "4xmhwc1u", // Marque
    //     "t0m901lf", // Elegoet
    //     "2nndgahu", // hello
    //     "lgbztig9" // Linda
    // ]

    React.useEffect(() => {
        if (images.length < 1) return null
        const newImagesURLs = []
        images.forEach(image => newImagesURLs.push(URL.createObjectURL(image)))
        setImagesURLs(newImagesURLs)
    }, [images, setImagesURLs])

    const validateInvIn = useRef()
    useEffect(() => {
        validateInvIn.current?.scroll(0, 0)
    }, [validateInvIn])

    const close = function () {
        setSel({})
        setValidation(false)
        setReport(false)
        setImages([])
        setImagesURLs([])
        props.close()
    }

    if (!props.data) {
        return null
    }

    const compressImage = async function (image) {
        console.log()
        return new Promise(resolve => {
            new Compressor(image, {
                quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                success: (compressedResult) => {
                    resolve(compressedResult)
                },
                error(err) {
                    console.log(err)
                }
            });
        })

    }

    if (sel?.property && showHomeDetails) {
        const property = userContext.data?.properties.find(el => el.name === sel.property)
        return (
            <div className={"mobilePage"} ref={overlayRef} onClick={(e) => {
                if (e.target === overlayRef.current) {
                    close()
                }
            }}>
                <div className={"body"}>
                    <div>
                        <div className={"handle"} />
                        <div className={"header"}>
                            <div className={"w-100"}>
                                <button className={'btn'} onClick={() => {
                                    setShowHomeDetails(false)
                                }}><FiArrowLeft /></button>
                                <p className={"text-center"}>Fiche home : {new Date(sel.date?.dateStart).toTimeString().substr(0, 5)} - {sel.property}</p>
                            </div>
                            <HomeViewPDF title={`Home-${property.name}`} data={property.homeView} />
                        </div>
                    </div>
                    <div ref={validateInvIn} className={"content"}>

                        {/* <HomeDetails data={userContext.data} id={sel.id} close={() => setShowHomeDetails(false)}/> */}
                        <HomeDetails sel={sel} data={userContext.data} close={() => setShowHomeDetails(false)} />
                    </div>
                </div>
            </div>
        )
    }

    if (sel?.property && showHomeDetailsv2) {
        const property = userContext.data?.properties.find(el => el.name === sel.property)
        return (
            <div className={"mobilePage"} ref={overlayRef} onClick={(e) => {
                if (e.target === overlayRef.current) {
                    close()
                }
            }}>
                <div className={"body"}>
                    <div>
                        <div className={"handle"} />
                        <div className={"header"}>
                            <div className={"w-100"}>
                                <button className={'btn'} onClick={() => {
                                    setShowHomeDetailsv2(false)
                                }}><FiArrowLeft /></button>
                                <p className={"text-center "}>Fiche home v2 : {new Date(sel.date?.dateStart).toTimeString().substr(0, 5)} - {sel.property}</p>
                            </div>
                            <HomeViewNewPDF title={`Home-${property.name}`} data={property.homeViewNew} />
                        </div>
                    </div>
                    <div className={"content"}>

                        {/* <HomeDetails data={userContext.data} id={sel.id} close={() => setShowHomeDetails(false)}/> */}
                        <HomeDetailsv2 sel={sel} data={userContext.data} close={() => setShowHomeDetailsv2(false)} />
                    </div>
                </div>
            </div>
        )
    }

    if (sel?.property && report) {
        const imageChange = function (e) {
            setImages([...e.target.files])
        }

        return (
            <div className={"mobilePage"} ref={overlayRef} onClick={(e) => {
                if (e.target === overlayRef.current) {
                    close()
                }
            }}>
                <div className={"body"}>
                    <div>
                        <div className={"handle"} />
                        <div className={"header"}>
                            <div className={"w-100"}>
                                <button className={'btn'} onClick={() => {
                                    setImages([])
                                    setImagesURLs([])
                                    setValidation(false)
                                }}><FiArrowLeft /></button>
                                <p className={"text-center "}>Validation {new Date(sel.date?.dateStart).toTimeString().substr(0, 5)} - {sel.property}</p>
                            </div>
                        </div>
                    </div>
                    <div className={"content"}>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            const fd = new FormData()

                            fd.append('report', document.getElementById('report').value)
                            fd.append('id', sel.id)

                            for (let i = 0; i < images.length; i++) {
                                fd.append('files', images[i]);
                            }


                            for (const val of fd) {
                                console.log(val)
                            }

                            userContext.apiReducer('validate', fd)
                                .then((response) => {
                                    console.log('reached')
                                })
                                .catch((err) => {
                                    alert(`Oups, quelque chose s’est mal passé.\n\nNous n’avons pas pu traiter votre demande. Veuillez réessayer.\nSi le problème persiste, veuillez nous contacter à hello@mypacome.fr\n\nCode d'erreur ${err} `)
                                    console.log(err)
                                })
                        }}>
                            <div className="mb-3 px-2">
                                <label htmlFor="report" className="form-label bold">Rapport de fin
                                    d'intervention</label>
                                <textarea className="form-control" id="report" rows="3" required />
                                <p className={"small text-muted"}>Inscrivez ici votre ce que vous avez fait, vos
                                    remarques, ainsi que les problèmes rencotrés s'il y en a eu.</p>
                            </div>
                            <hr />
                            <div className="mb-3 px-2">
                                <label htmlFor="files" className="form-label bold">Photos de votre interventions</label>
                                <p className={"small text-muted"}>Vos photos doivent être prises le jour même de
                                    l'intervention et refléter le travail effectué.</p>
                                <input id={"files"} type="file" multiple="multiple" accept="image/*"
                                    onChange={imageChange} required={!/Check(-?| )?(out|in)/i.test(sel.description?.description)} />

                            </div>
                            <div>
                                <p>Nombre d'images : {imagesURLs.length}</p>
                            </div>
                            <div className={"imagesList d-inline-flex"}>
                                {imagesURLs.map((url, index) => <img src={url} className={"ml-1"} alt={index} />)}
                            </div>
                            <div className={"validationSection w-100"}>
                                <hr className={"mb-2"} />
                                <button className={"btn btn-success w-100"} type={"submit"}><FiSend
                                    className={"mr-3"} /> Envoyer mon rapport
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )

    }

    if (sel?.property && validation) {
        const imageChange = function (e) {
            const files = Array.from(e.target.files)

            Promise.all(files.map(async (image) => {
                return new Promise((resolve, reject) => {
                    compressImage(image)
                        .then((result) => {
                            resolve(result)
                        })
                })
                    .then((result) => {
                        return result
                    })
            })
            )
                .then((res) => {
                    setImages(res)
                })
        }
        return (
            <div className={"mobilePage"} ref={overlayRef} onClick={(e) => {
                if (e.target === overlayRef.current) {
                    close()
                }
            }}>
                <div className={"body"}>
                    <div>
                        <div className={"handle"} />
                        <div className={"header"}>
                            <div className={"w-100"}>
                                <button className={'btn'} onClick={() => {
                                    setImages([])
                                    setImagesURLs([])
                                    setValidation(false)
                                }}><FiArrowLeft /></button>
                                <p className={"text-center "}>Validation {new Date(sel.date?.dateStart).toTimeString().substr(0, 5)} - {sel.property}</p>
                            </div>
                        </div>
                    </div>
                    <div ref={validateInvIn} className={"content"}>
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            setLoading(true)
                            const fd = new FormData()
                            for (let i = 0; i < images.length; i++) {
                                fd.append('files', images[i]);
                            }
                            if (document.getElementById('report')) {
                                fd.append('report', document.getElementById('report').value)
                            } else {
                                const inventory = {}
                                let where = sel.description.description.includes("Check in") ? "entree" : sel.description.description.includes("Check out") ? "sortie" : ""
                                inventory[where] = {}
                                for (const input of e.target.elements) {
                                    inventory[where][input.name] = input.value
                                }
                                fd.append('inventory', JSON.stringify(inventory))
                                fd.append('people', JSON.stringify(sel.people))
                            }
                            fd.append('id', sel.id)
                            if (navigator.onLine) {
                                userContext.apiReducer('validate', fd)
                                    .then((response) => {
                                        setLoading(false)
                                        setValidation(false)
                                        setImages([])
                                        setImagesURLs([])
                                        window.location.reload()
                                        return false
                                    })
                                    .catch((err) => {
                                        alert(`Oups, quelque chose s’est mal passé.\n\nNous n’avons pas pu traiter votre demande. Veuillez réessayer.\nSi le problème persiste, veuillez nous contacter à hello@mypacome.fr\n\nCode d'erreur ${err} `)
                                        setLoading(false)
                                    })
                            }
                            else {
                                alert(`Vous n'êtes pas connecté à internet, le rapport a été sauvegardé localement, vous pourrez l'envoyer plus tard via le bouton "États des lieux en attente" de CM.`);
                                const plainObject = {};
                                for (const [key, value] of fd.entries()) {
                                    if (key !== 'files') {
                                        plainObject[key] = value;
                                    } else {
                                        if (!plainObject.files) {
                                            plainObject.files = [];
                                        }
                                        // Store the file object including the name
                                        plainObject.files.push({
                                            name: value.name,
                                            content: null // Placeholder for the base64 content
                                        });
                                    }
                                }
                                if (plainObject.report) { // save the date of the report with it
                                    plainObject.date = new Date().toISOString();
                                    // Convert the File type image to a base64 string to be able to save it in localStorage
                                    const fileReaders = plainObject.files.map((fileObj, index) => {
                                        return new Promise((resolve) => {
                                            const reader = new FileReader();
                                            reader.onload = function (e) {
                                                // Update the file object with the base64 content
                                                plainObject.files[index].content = e.target.result;
                                                resolve();
                                            };
                                            reader.readAsDataURL(fd.get('files'));
                                        });
                                    });

                                    Promise.all(fileReaders).then(() => {
                                        localStorage.setItem('savedFormData', JSON.stringify(plainObject));
                                        setLoading(false);
                                    });
                                }
                            }
                        }
                        }>
                            {/* {console.log(sel.booking)} */}
                            {
                                // If its a check-in or check-out, make the concierge fill out the inventory
                                // If in an edge case its a check in but somehow the booking isnt found then default report behavior
                                (sel.description.description.includes("Check in") || sel.description.description.includes("Check out")) && !sel.booking ? <ValidateDefault sel={sel} imageChange={imageChange} imagesURLs={imagesURLs} loading={loading} />
                                    : sel.description.description.includes("Check in") ?
                                        <ValidateInventoryCheckIn sel={sel} imageChange={imageChange} imagesURLs={imagesURLs} loading={loading} userContext={userContext} />

                                        :
                                        sel.description.description.includes("Check out") ?
                                            <ValidateInventoryCheckOut sel={sel} imageChange={imageChange} imagesURLs={imagesURLs} loading={loading} userContext={userContext} />

                                            :
                                            <ValidateDefault sel={sel} imageChange={imageChange} imagesURLs={imagesURLs} loading={loading} />
                            }
                            <div className={"validationSection w-100"}>
                                <hr className={"mb-2"} />
                                {/* disabled={sel.valide && sel.valid}  */}
                                <SubmitButton disabled={sel.valide && sel.valid} className={"btn btn-success w-100"} loading={loading.toString()} >
                                    <FiSend className={"mr-3"} />{sel.valide && sel.valid ? "Rapport déjà envoyé" : "Envoyer mon rapport"}
                                </SubmitButton>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )

    }

    if (sel?.property) {
        const burl = userContext.getBurl()
        const property = userContext.data?.properties.find(el => el.name === sel.property)
        const addressToString = Object.entries(property.address).map(el => el[1]).reverse().join(' ')
        const duration = sel.date.duration.split(':').join('h')
        const coordinates = property.geo.lat && property.geo.long ?
            `https://www.google.com/maps?q=${property.geo.lat},${property.geo.long}`
            : null
        let interventionDate = new Date(sel.date?.dateStart).toISOString().substr(11, 5)
        return (
            <div className={"mobilePage"} ref={overlayRef} onClick={(e) => {
                if (e.target === overlayRef.current) {
                    close()
                }
            }}>
                <div className={"body"}>
                    <div>
                        <div className={"handle"} />
                        <div className={"header"}>
                            <div className={"w-100"}>
                                <button className={'btn'} onClick={() => {
                                    setSel({})
                                }}><FiArrowLeft /></button>
                                <p className={"text-center "}>{interventionDate} - {sel.property}</p>
                            </div>

                        </div>
                    </div>
                    <div ref={validateInvIn} className={"content content-details"}>
                        <p><b>{addressToString}</b></p>
                        <p>L'intervention dure <b>{duration}</b></p>
                        <p className='description'>Description de l'intervention : <br /> <br /> {sel.description?.description}</p>
                        <p>Se rendre sur place : </p>
                        <button className={'btn btn-blue mx-2'} disabled={!coordinates} onClick={() => {
                            window.open(coordinates, '_blank').focus();
                        }}><FiMap className={"mr-3"} /> Ouvrir sur la carte
                        </button>
                        <p>Informations sur la propriété :</p>

                        <button className="btn btn-dark mx-2 mt-2" onClick={() => setShowHomeDetails(true)}>Fiche Home
                        </button>

                        <button hidden={property.homeViewNew.length < 1} className="btn btn-dark mx-2 mt-2" onClick={() => setShowHomeDetailsv2(true)}>Fiche Home V2
                        </button>

                        {sel.people.find(people => people.people === userContext.user.id && sel.valide, "sel")?.report ?
                            (<>
                                <hr className={"mb-2"} />
                                <p className='description'>Intervention du {new Date(sel.people.find(people => people.people === userContext.user.id && sel.valide, "sel")?.finishDate).toISOString().substr(0, 10)} :</p>
                                <p>{sel.people.find(people => people.people === userContext.user.id && sel.valide, "sel")?.report}</p>
                            </>
                            ) : null}
                        <div className={"imagesList d-inline-flex mt-3"}>
                            {sel.people.find(people => people.people === userContext.user.id && sel.valide)?.photo.map((url, index) =>
                                <img src={burl + "/" + url} className={"ml-1"} alt={index}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = altImg;
                                    }} />
                            )}
                        </div>
                        <div className={"validationSection"} hidden={sel.valide && !(sel.description.description.includes("Check in") || sel.description.description.includes("Check out"))}>
                            <hr className={"mb-2"} />
                            <button className={"btn btn-danger w-100 mb-3"} hidden disabled aria-disabled><FiX
                                className={"mr-3"} /> Signaler un problème <small>- En cours de développement</small>
                            </button>
                            <button className={"btn btn-success w-100"} onClick={() => {
                                validateInvIn.current?.scroll(0, 0)
                                setValidation(true)
                            }}><FiSend className={"mr-3"} />
                                {(sel.description.description.includes("Check in") || sel.description.description.includes("Check out")) && sel.booking ?
                                    sel.valide ? "Visualiser l'état des lieux rempli" :
                                        "Remplir l'état des lieux" :
                                    "Terminer mon intervention"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"mobilePage"} ref={overlayRef} onClick={(e) => {
            if (e.target === overlayRef.current) {
                props.close()
            }
        }}>
            <div className={"body"}>
                <div>
                    <div className={"handle"} />
                    <div className={"header"}>
                        <p className={"text-center mb-0 my-auto"}>{props.header}</p>
                    </div>
                </div>

                <div className={"content"}>
                    <div className="list-group">
                        {
                            props.data.sort((a, b) => a.date.dateStart > b.date.dateStart ? 1 : -1).map((intervention) => {

                                let interventionDate = new Date(intervention.date?.dateStart).toISOString().substr(11, 5)

                                const classItem = intervention.valide ? "list-group-item list-group-item-action mb-0 green" : "list-group-item list-group-item-action mb-0 red"
                                return (
                                    <div className={classItem} onClick={() => {
                                        setSel(intervention)
                                    }}>
                                        <p className={"bold"}>{interventionDate} - {intervention.property}</p>
                                        <p>{intervention.date?.duration.split(":").join('h')} : <br />{intervention.description?.description}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}